





































import type { Categoria } from '@/models'
import { TipoDeOperacao } from '@/models/Recurso'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { SaveCategoriaUseCase } from '@/usecases'
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'

@Component
export default class DialogoDeEdicaoDeCategoria extends Vue{
	@Ref() form!: HTMLFormElement
	@Ref() campoDeNome!: HTMLInputElement
	@Prop({type: Object}) categoria?: Categoria

	saveUseCase = new SaveCategoriaUseCase()
	salvando = false
	mostra = false
	criandoCategoria = false
	categoriaEmEdicao: string|undefined = undefined


	mostrar(){
		this.onChangeCategoria()
		this.mostra = true

		setTimeout(() => {
			this.form.resetValidation()
			this.campoDeNome.focus()
		})
	}

	get podeAlterarMotivo() {
		return this.podeRealizarOperacao('alterar')
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !'categoria' || UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'categoria'))
	}

	esconder(){
		this.categoriaEmEdicao = undefined
		this.mostra = false
	}

	async salvar() {
		if (!this.categoria) return
		if (!this.categoriaEmEdicao) return
		if (!this.form.validate()) return
		try {
			this.salvando = true

			this.editarCategoria()

			const categoriaSalva = this.categoria.id
				? await this.saveUseCase.update(this.categoria)
				: await this.saveUseCase.create(this.categoria)

			this.$emit('confirmado', categoriaSalva)
			this.mostra = false
			AlertModule.setSuccess('Categoria salva com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	editarCategoria(){
		if(this.categoria && this.categoriaEmEdicao)
			this.categoria.nome = this.categoriaEmEdicao
	}

	@Watch('categoria')
	onChangeCategoria(){
		this.categoriaEmEdicao = this.categoria?.nome
	}
}
