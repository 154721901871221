











































import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { Categoria } from '@/models'
import { FindCategoriaUseCase } from '@/usecases'
import DialogoDeEdicaoDeCategoria from '@/components/produto/DialogoDeEdicaoDeCategoria.vue'
import { DataOptions } from 'vuetify'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'


@Component({
	components: {
		DataTableDeCrud,
		DialogoDeEdicaoDeCategoria,
	},
})
export default class TelaDeCategoria extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeCategoria

	busca = ''
	categorias: Categoria[] = []
	cancelToken: CancelTokenSource | null = null
	totalRegistros = -1
	carregando = false
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	findUseCase = new FindCategoriaUseCase()
	headers = [
		{ text: 'Categoria', value: 'nome' },
	]
	categoria: Categoria| null = null

	created(){
		this.buscar()
	}

	editar(indice: number) {
		this.categoria = this.categorias[indice]
		if (!this.categorias) return
		this.dialogoDeEdicao.mostrar()
	}

	atualizaCategoria(categoria: Categoria){
		if (!categoria) return
		const indice = this.categorias.findIndex(
			categoriaExistente => categoriaExistente.id === categoria.id,
		)
		indice === -1
			? this.categorias = [ ...this.categorias, categoria ]
			: this.categorias.splice(indice, 1, categoria)
		++this.totalRegistros
	}

	criar() {
		this.categoria = {id:"", nome:""}
		this.dialogoDeEdicao.mostrar()
	}

	get categoriasFormatados(){
		return this.categorias.map( categoria => ({
			...categoria,
		}))
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try{
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				busca: this.busca || undefined,
				size: this.paginacao.itemsPerPage,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.findUseCase.findAll(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.categorias = page.content
		} catch(error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao(){
		this.buscar()
	}

	@Watch('busca')
	onChangeBusca() {
		this.buscar()
	}
}
